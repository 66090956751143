
import {
  saveMoneyType
} from "@/common/constant";
import {
  // 购物车提交
  cartSubmit,
  // 查看预占用额度明细
  quotaDeatail,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory,
 findOrderConfirmInit, findProductInfoInit, orderSubmitFX
} from "./api";
// import { ctsApi } from "@/views/channel/api";
import { getSupplierS } from "@/views/channelSubOrder/api";
import { getWarehouseList } from "@/views/order/hisenseProcurementProgress/api";
import moment from "moment";
import { checkShipTo } from "@/views/settlement/api";
import addressModal from "@/views/settlement/components/addressModal";

export default {
  name: "",
  components: {
    addressModal
  },
  data() {
    return {
      visibleAddress:false,
      newTown:'',
      modelShow: false,
      // 查看占用明细列表
      columns: [{
        title: '销售组织',
        dataIndex: 'orgName',
        key: 'orgName',
        width:100
      },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'amatklNamege',
          width:100
        },
        {
          title: '订单编码',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width:150
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width:100
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          width:100
        },
        {
          title: '商品型号',
          dataIndex: 'zzprdmodel',
          key: 'zzprdmodel',
          width:100
        },
        {
          title: '商品数量',
          dataIndex: 'qty',
          key: 'qty',
          width:100
        },
        {
          title: '付款状态',
          dataIndex: 'fkStatus',
          key: 'fkStatus',
          width:100
        }, {
          title: '订单金额',
          dataIndex: 'orderAmt',
          key: 'orderAmt',
          width:100
        }, {
          title: '预占用金额',
          dataIndex: 'waitBalance',
          key: 'waitBalance',
          width:120
        }, {
          title: '要求到货周次',
          dataIndex: 'weekName',
          key: 'weekName',
          width: 200
        },
        {
          title: '下单日期',
          dataIndex: 'createdDate',
          key: 'createdDate',
          width:150
        },
      ],
      // 查看占用明细列表
      data: [],


      form: {
        shopName: '', //出样门店名称
        wareHouse: '', //出样仓库仓库名称（原来准备取门店地址，现在此字段从DMS取）
        shippingAddress: '', //地址
        orderRemark: '', //订单备注
        contactPhone: '', //联系方式
        contactPerson: '', //联系人
        billToName:'', //开票户头
        billToId:'', //开票户头id
        expireTime:'', // 最大下单日
        shippingAddressId:'',
        supplierCisCode:'',
        wareHouseName:'',
        agentAddressId:''
      },
      // 供应商为海信时显示供应商为物料组
      suppilers: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      obj: {},
      GoodsList: [],
      orgId:'',
      materialGroupId:'',
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      // 上个页面传过来的参数
      RouterParams: {},
      // 配送方式
      trans: '',
      // 物料组id
      matklId: '',
      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/prototyZone",
          name: "prototyZone",
          title: "样机专区"
        },
        {
          path: "/confirm",
          name: "confirm",
          title: "订单结算"
        }
      ],

      //返利按钮是否选中
      isTradeChecked: true,
      //返利金额
      rebate: 0,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      // 提交按钮loading
      isloading: false,
      TotalPaymentss: {},
      realData: {},
      pageLoadFlagTable: false,
      pageLoadFlag: false,
      isLoading: false,
      pageLoad: true,
      pujieS: false,
      saveMoneyValue: {},
      URLTO: '',
      onluyOneMessage:false,
      mainMsg:'',
      orgMsg:'',
      orderNumber:'',
      tradeType:true,
      userId:'',
      operateFlag: 1,
      shopInfoId:'',
      arrivalTimeStr:'',

    };
  },

  mounted() {
    // 上个页面穿过来的数据 进行基础数据的查询
    this.RouterParams = this.$route.query;
    // 配送方式  转单和样机专区下单要区分
    this.trans =  this.$route.query.type == 'yangji'?'502001':'502005'
    // 展示的供应商的名称
    this.suppilers = this.$route.query.agentAndSupplier
    this.operateFlag = this.$route.query.operateFlag
    if(this.$route.query.type !== 'yangji' ){
      this.orderNumber = this.$route.query.orderNumber

    }
    this.URLTO = saveMoneyType;
    this.getTopInfo()
    this.cglrywy()
  },
  methods: {
    // 期望送货日期时间选择
    arrivalTimeChange(value,dateString) {
      this.arrivalTimeStr = dateString;
    },
    // 查看入库仓库接口
    getWhouse(){
      let data = {
        loginType: "CS",
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        orgId:this.$route.query.orgId,
        warehouseType:'70',
        warehouseCode:this.$route.query.type == 'yangji'?this.$store.state.user.userInfo.customer.customerCode:this.$route.query.dealerCode,
        dealerCode:this.$route.query.type == 'yangji'?this.$store.state.user.userInfo.customer.customerCode:this.$route.query.dealerCode

      };
      getWarehouseList(data).then(res=>{
        var obj = JSON.parse(res.data);
        this.form.wareHouseName = obj.data.length>0?obj.data[0].name:'';
        this.form.wareHouse = obj.data.length>0?obj.data[0].cId:'';
        this.$forceUpdate()
         // =  obj.warehouseCode
      })
    },
    // 查看数据详情
   async getTopInfo(){
      this.pageLoadFlag = true;
      let data  = {
        shopCode: this.$route.query.shopCisCode,
        materialGroupCode: this.$route.query.materialGroupCode,
      }
     await findOrderConfirmInit(data).then(res=>{
       if(res.data.code == 0){
         this.form = {
           shopName: res.data.data.shopName,
           // wareHouse: res.data.data.wareHouse,
           shippingAddress: res.data.data.shippingAddress,
           orderRemark:this.$route.query.type == 'yangji'? res.data.data.orderRemark : this.$route.query.remark,
           contactPhone: res.data.data.contactPhone,
           contactPerson: res.data.data.contactPerson,
           billToName:res.data.data.billToName, //开票户头
           billToId:res.data.data.billToId, //开票户头id
           expireTime: res.data.data.expireTime, // 最大下单日
           shippingAddressId:res.data.data.shippingAddressId,
           supplierCisCode:res.data.data.supplierCisCode,
           agentAddressId:res.data.data.agentAddressId
         }
         this.shopInfoId = res.data.data.shopInfoId
         this.getWhouse()
         this.getProductInfoInit()
         this.pageLoadFlag = false;
       } else {
         this.$message.warning(res.data.msg)
         this.pageLoadFlag = false;
       }
      })
    },
    // 查询商品信息
    getProductInfoInit(){
      let data = {
        "orgId":this.$route.query.orgId,//分公司ID
        "productInfoQueryList":JSON.parse(this.$route.query.productInfoQueryList)
      }
      findProductInfoInit(data).then(res => {
        if(res.data.code == 0){
          this.GoodsList = res.data.data.productDTOList
          this.orgId = res.data.data.productDTOList.length> 0 ? res.data.data.productDTOList[0].orgId : ''
          this.materialGroupId = res.data.data.productDTOList.length> 0 ? res.data.data.productDTOList[0].materialGroupId : ''
          this.goodsNum = res.data.data.totalQuantity
          this.goodsVolume = res.data.data.totalVolume
          this.goodsPrice = res.data.data.totalPrice
          // 返利金额
          this.rebate = res.data.data.rebate?res.data.data.rebate:0
          // 预占用金额
          this.TotalPayment.waitMoney = res.data.data.occupiedBalance?res.data.data.occupiedBalance:0
          // 账户可用余额
          this.TotalPayment.balanceAccount = res.data.data.accountBalance?res.data.data.accountBalance:0
          // 账户余额
          this.TotalPayment.canUseMoney = res.data.data.availableBalance?res.data.data.availableBalance:0
          // this.GoodsList.forEach((item, index) => {
          //   try {
          //     ctsApi({
          //       serviceCode: "agentDirectDealerReturnPolicy",
          //       bigContractNo: "",
          //       companyCode: item.orgCode,
          //       contractNo: "",
          //       distributorsCisCode: "",
          //       dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
          //       model: item.modelSap || item.productModel,
          //       pricingGroup: "10",
          //       tableRow: "",
          //       isActivityArea: false
          //     }).then(res=>{
          //       if(res && res.data && res.data.data && res.data.data.model){
          //         item.isShowRebate = true;
          //         item.returnAmount = res.data.data.returnAmount;
          //         item.upperLimit = res.data.data.upperLimit;
          //         this.$set(this.GoodsList[index], index, item)
          //       }
          //     })
          //   } catch (error) {
          //   }
          // })
        } else {
          this.$message.warning(res.data.msg)
        }
      }).catch(error=>{
        console.log(error);
      })
    },

    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //是否返利点击
    tradeOnChange(e) {
      this.isTradeChecked = e.target.checked;
    },
    getAddress(e) {
      this.newTown = e[3]
    },
    async isCheckShipTo(){
      let res = await checkShipTo({shipToId:this.form.shippingAddressId })
      return res.data.data.isInvalidRegion
    },
    // 点击提交订单按钮（代理下单）
    async orderSubmit() {
      if(this.form.shippingAddressId && !this.newTown) {
        let checkRes= await this.isCheckShipTo()
        if(checkRes == 1) {
          // 上面不符合条件的弹出弹窗
          this.visibleAddress = true
          return
        }
      }

      var waitmoney = 0;
      // 订单金额
      var orderCount = this.goodsPrice;
      // 选择使用返利金额时
      if (this.isTradeChecked) {
        waitmoney = this.TotalPayment.balanceAccount + this.rebate
      } else {
        //不使用返利金额时
        waitmoney = this.TotalPayment.balanceAccount
      }
      if (Number(orderCount) > Number(waitmoney)) {
        this.$message.warning('账户金额小于订单金额无法下单！')
        this.isLoading = false;
        return;
      }

      this.isLoading = true;
      // 产品id
      var proIds =  this.GoodsList.map(it => it.productId).join(',')
      // 选择数量
      var chooseNumAll =  this.GoodsList.map(it => it.quantity).join(',')
      // 政策id
      var versionsAll = this.GoodsList.map(it => it.pricePolicyId).join(',')
      let data = {
        prototypeShopId: this.shopInfoId , // 样机专卖店id 接口返回
        purchaseType:  '', // 不需要
        warehouseCode: this.form.wareHouse, // 原仓编码 ，dms获取
        remark: this.form.orderRemark?this.form.orderRemark:'', // 备注
        orderType:'8320',
        weekName: '',// 不需要传
        orgAndGroup: this.orgId +'-'+ this.materialGroupId,// 接口里返回 拼接
        orderId: '', //// 不需要传
        products: proIds, // 产品id
        productNumbers: chooseNumAll,// 产品数量
        versions: versionsAll, // 政策id
        orderRebate: this.isTradeChecked ? 'Y' : 'N', //
        billTo: this.form.billToId,//开票户头
        address1: this.$route.query.type !== 'yangji' ? this.form.agentAddressId : this.form.shippingAddressId, // 收货地址id
        trans: this.trans,// 配送方式  代理502001  分销502005
        // 用户手填地址
        address3: '', // 不需要  自提时候手填
        // 分销商地址  id
        address4:this.$route.query.type !== 'yangji'? this.form.shippingAddressId:'', // 分销传
        contact: this.form.contactPerson,//接口
        mobile: this.form.contactPhone,//接口
        orderCode: this.orderNumber, // 转单有  下单没有 接口
        maxEndDate: this.form.expireTime ? this.form.expireTime : '', //接口
        endDate: this.form.expireTime  ? this.form.expireTime  : '', //接口
        arrivalTimeStr:this.arrivalTimeStr?this.arrivalTimeStr+' 00:00:00':'',
        officeId: '', //不需要
        // 是否允许提前发货
        isAllowAdvancdeliver: '', // 不需要
        salesShopInfoId: '',//不需要
        // 服务列表
        serviceTypeCodes:'',//不需要
        district:  '',//不需要
        town:  '',//不需要
        validTown:this.form.shippingAddressId?this.newTown:''
      }
      cartSubmit(data).then(res => {
        if(res.data.frontMsg) {
          // 弹出提示信息
          this.mainMsg =  res.data.frontMsg.msgDesc
          this.orgMsg = res.data.frontMsg.tip
          this.onluyOneMessage = true
          this.isLoading = false;
          return
        }else if (res.data.error) {
          this.$message.warning(res.data.error);
          this.isLoading = false;
        } else if (res.data.code == 400) {
          this.$message.warning(res.data.msg);
          this.isLoading = false;
        }
        else if (res.data.b2bOrderCode) {
          this.$router.push({
            path: '/prototyZone/success',
            query: {
              b2bOrderCode: res.data.b2bOrderCode,
              orgName: res.data.orgName
            }
          })
        }
        this.isLoading = false;
      })
    },

    // 分销商查询业务员id
    cglrywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "cglrywy"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
        this.userId = Object.keys(obj.data[0])[0];
      })
    },
    // 分销下单
    async orderSubmitFX() {
      this.isLoading = true
      // 政策id
      // var versions1 = this.GoodsList.map(it => it.pricePolicyId).join(',')
      let purchaseOrderItem = []
      this.GoodsList.forEach(item=>{
        purchaseOrderItem.push({
          productCode: item.productId, // 产品编码
          model: item.productModel, // 产品型号
          orderedQty: item.quantity, // 采购数量
          price: item.price, // 采购价格
          amount: item.quantity * item.price, // 采购金额小计
          versions:item.versions
        });
      })
     let data = {
       cisCode: this.$store.state.user.userInfo.customer.cisCode,
       purchaseOrder: {
         arrivalTimeStr:this.arrivalTimeStr?this.arrivalTimeStr+' 00:00:00':'',
         yjStoreCode:this.$route.query.shopCisCode, // 样机门店
         orgCode: this.orgId, // 组织编码
         contact:  this.form.contactPerson, // 联系人
         contactInfo: this.form.contactPhone, // 联系方式
         supplierCode: this.form.supplierCisCode, // 供应商编码
         cisAddressId: this.form.shippingAddressId, // 收货地址id
         address: this.form.shippingAddress, // 详细地址取收货地址
         userId:  this.userId? this.userId:'', // 采购人员id
         warehouseId:  this.form.wareHouse, // 入库仓库编码  嘉赫接口获取
         totalAmount: this.goodsPrice, // 采购总额
         totalOrderedQty: this.goodsNum, // 采购总数
         deliveryMode: '02', // 配送方式
         message: this.form.orderRemark?this.form.orderRemark:'', // 订单备注
         orderType: "", // 样机类型
         isProtoType:true,
         // versions: versions1, // 政策id
         purchaseOrderItem
       }
     }
      orderSubmitFX(data).then(res => {
        this.isLoading = false;
        var obj = JSON.parse(res.data);
        if (obj.code == '1') {
          this.isLoading = false;
          this.$message.warning(obj.msg);
        } else if (obj.code == '0') {
          this.isLoading = false;
          this.$router.push({
            path: '/prototyZone/success',
            query: {
              b2bOrderCode: obj.msg,
              orgName: '订单号',
              channel:1
            }
          })
        }
      }).catch(error=>{
        this.isLoading = false;
      })
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回
    goBack() {
      this.$router.push("/prototyZone");
    },



    // 查看预占用明细弹框
    ToDetail() {
      this.modelShow = true;
      var data = {
        orgIdParam: this.$route.query.orgId,
        matklIdParam: this.$route.query.materialGroupId,
        weekName: '',
        purchaseType: '8320'
      }
      this.pageLoadFlagTable = true;
      quotaDeatail(data).then(res => {
        this.pageLoadFlagTable = false;
        this.dataAll = res.data.dataList;
        this.data = res.data.dataList.slice(0, 10)
        this.pager.count = res.data.dataList.length;
        // this.pager.pageSize = res.data.dataList.length;
      })
    },
    // 分页
    pagesiezes(data) {
      // var prev = data - 1;
      var start = (data - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize)
      this.pager.pageSize;
    },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
  }
}
import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";



// 在线充值
export function saveMoney() {
  return http({
    url: '/cart/selfOrderPay.nd',
    method: 'get'
  })
}
// 充值记录

export function saveHisetory() {
  return http({
    url: '/cart/selfOrderQuery.nd',
    method: 'get'
  })
}

//时效信息
export function orderWeek(data) {
  return http({
    method: "post",
    url: "/cart/orderWeek.nd",
    data
  });
}
// 要求到货周余额查询
export function moneyWeek(data) {
  return http({
    method: 'post',
    url: '/cart/moneyByWeek.nd',
    data
  })
}
// 购物车查询
export function orderCar(params) {
  return http({
    url: '/cart/settlement.nd',
    method: 'get',
    params
  })
}





// 要求到货周
export function getWeek(data) {
  return http({
    url: '/cart/orderWeek.nd',
    method: 'post',
    data
  })
}



// 订单提交（再来一单）
export function cartSubmit(data) {
  return http({
    url: '/cart/saveAnotherOrder.nd',
    method: 'post',
    data
  })
}
// 查看预占用额度明细
export function quotaDeatail(params) {
  return http({
    url: 'balance/queryWaitBalanceInfoList.nd',
    method: 'get',
    params
  })
}


// 初始化头部数据
export function findOrderConfirmInit(params) {
  return http({
    url: '/prototype/findOrderConfirmInit.nd',
    method: 'get',
    params,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  })
}
// 初始化商品信息
export function findProductInfoInit(data) {
  return http({
    url: '/prototype/findProductInfoInit.nd',
    method: 'post',
    data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
  })
}


// dms 订单提交
export function orderSubmitFX(data) {
  return dmshttp({
    url: 'submitPurchaseOrder',
    data
  })
}
